import { ActionTaken, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { dateUpdateFormat } from '@/lib/date-update-format'
import { DEFAULT_INITIATIVE_CATEGORY, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import CategoryIcon from '@/components/beeoinitiative/category-icon'
import { Icons } from '@/components/icons'

interface TakenPlannedInitiativesProps {
  title: string | number
  footer: string
  button: boolean
  initiativeList: ActionTaken[]
  seeMoreCard?: boolean
  setSeeMoreCard: (value: boolean) => void
  onMoveToDone: (id: number) => void
  site: Site
}
const TakenPlannedInitiativesCard = ({
  title,
  footer,
  button,
  initiativeList,
  seeMoreCard,
  setSeeMoreCard,
  onMoveToDone,
  site,
}: TakenPlannedInitiativesProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Card
        className={`md:mb-4 md:px-8 md:py-9 lg:mb-4 lg:w-full xl:mb-0 2xl:mb-0 2xl:px-10 2xl:py-9  ${!seeMoreCard ? 'lg:max-h-[435px]' : 'md:max-h-[800px]'}`}
      >
        <div className="flex space-x-2 pb-6 text-xl font-semibold">
          <p className="flex flex-col">{title}</p>
        </div>
        <Separator className="bg-separatorGray" />
        <div
          className={`${seeMoreCard ? 'flex h-[500px] flex-col space-y-8 overflow-y-scroll px-4 py-8' : 'flex h-[281px] flex-col space-y-8 overflow-y-hidden px-4 py-8'}`}
        >
          {initiativeList.map((initiative, index) => {
            const initiativeCategory = initiative.initiative.category
              ? INITIATIVE_CATEGORIES[initiative.initiative.category]
              : DEFAULT_INITIATIVE_CATEGORY

            return (
              <div className="flex items-center justify-between ">
                <div>
                  <div key={index} className="flex flex-row items-center pb-3">
                    <div
                      className="flex size-[26px] items-center justify-center rounded-xl border"
                      style={{
                        borderColor: initiativeCategory.color,
                        backgroundColor: initiativeCategory.color + '10',
                      }}
                    >
                      {initiativeCategory.iconName && (
                        <CategoryIcon
                          name={initiativeCategory.iconName}
                          color={initiativeCategory.color}
                          className="size-4"
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-between px-2 pt-[2px]">
                      {site && (
                        <Link
                          to={getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN, {
                            params: {
                              siteId: site?.id,
                              initiativeId: initiative.initiative_id,
                              actionTakenId: initiative.id,
                            },
                          })}
                          className="text-lg font-bold leading-5 text-gray-800"
                        >
                          {initiative.initiative.short_name}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-4 text-sm">
                    <span>
                      {initiative.status === 'DONE'
                        ? dateUpdateFormat(initiative.date_done ?? null)
                        : initiative.status === 'PENDING'
                          ? dateUpdateFormat(initiative.date_start ?? null)
                          : null}
                    </span>
                    <Separator className="self-center py-1.5" orientation="vertical" />
                    <div className="flex items-center space-x-2">
                      <span>
                        <Icons.Leaf className="size-4 text-biodivTealSevenHundred" />
                      </span>
                      <span>{initiative.initiative.impact} pts</span>
                    </div>
                    <Separator className="self-center bg-separatorGray py-1.5" orientation="vertical" />
                    <span>
                      {initiative.status === 'DONE'
                        ? initiative.spent_budget ?? 0
                        : initiative.status === 'PENDING'
                          ? initiative.planned_cost ?? 0
                          : 0}{' '}
                      €
                    </span>
                  </div>
                </div>
                {button && (
                  <>
                    <Button
                      className="h-9 w-24 border-black text-black"
                      variant="outline"
                      onClick={() => onMoveToDone(initiative.id)}
                    >
                      {t(i18nKeys.beeoinitiative.site.close)}
                    </Button>
                  </>
                )}
              </div>
            )
          })}
        </div>
        <Separator className="bg-separatorGray" />
        <div
          onClick={() => setSeeMoreCard(!seeMoreCard)}
          className="flex cursor-pointer items-center justify-center py-4 underline"
        >
          {footer}
        </div>
      </Card>
    </>
  )
}

export default TakenPlannedInitiativesCard
