import { Button } from '@/components/ui/button'

interface CustomOutlineButtonProps {
  icon?: React.ReactNode
  label: string
}

const CustomOutlineButton = ({ icon, label }: CustomOutlineButtonProps) => {
  return (
    <Button variant="outline" className="relative flex items-center justify-center w-52 border border-black px-4 py-2">
      {icon && <span className="absolute left-4">{icon}</span>}
      <span className="ml-10 text-sm font-semibold">{label}</span>
    </Button>
  )
}

export default CustomOutlineButton
