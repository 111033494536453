import { Initiative, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { FormCreateActionTaken } from '@/pages/beeoinitiative/form-create-action-taken'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { DEFAULT_INITIATIVE_CATEGORY, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardDescription, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import CategoryIcon from '@/components/beeoinitiative/category-icon'
import { Icons } from '@/components/icons'
import Rating from '@/components/rating'

interface InitiativeCardProps {
  initiative: Initiative
  site: Site
}
const InitiativeCard = ({ initiative, site }: InitiativeCardProps) => {
  const { t } = useTranslation()
  const initiativeCategory = initiative.category
    ? INITIATIVE_CATEGORIES[initiative.category]
    : DEFAULT_INITIATIVE_CATEGORY

  return (
    <Card
      className="flex h-[400px] w-full flex-col justify-between overflow-hidden bg-white text-sm"
      style={{ borderColor: initiativeCategory.color + '95' }}
    >
      <img
        src={initiative.image1}
        className="h-36 w-full rounded-t-md object-cover opacity-90"
        alt="initiative nature"
      />
      <div className="flex flex-row px-6">
        <div
          className="flex size-[26px] items-center justify-center rounded-xl border"
          style={{ borderColor: initiativeCategory.color, backgroundColor: initiativeCategory.color + '10' }}
        >
          {initiativeCategory.iconName && (
            <CategoryIcon name={initiativeCategory.iconName} color={initiativeCategory.color} className="size-4" />
          )}
        </div>
        <div className="flex flex-col justify-between px-2 pt-[2px]">
          <CardTitle className="text-lg font-bold leading-5 text-gray-800">{initiative.short_name}</CardTitle>
          <CardDescription className="mt-1 text-xs" style={{ color: initiativeCategory.color }}>
            {t(initiativeCategory.labelKey)}
          </CardDescription>
        </div>
      </div>
      <div className="flex flex-col justify-end px-6">
        <Separator />
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col px-5 py-4">
            <span className="capitalize">{t(i18nKeys.beeoinitiative.initiativesPage.page.impact)}</span>
            <Rating
              rating={initiative.impact ?? -1}
              filledColor="text-[#1F6F6C]"
              unfilledColor="text-[#D1D1D1]"
              icon={<Icons.Leaf className="size-3" />}
            />
          </div>
          <Separator className="w-10 rotate-90" />
          <div className="flex flex-col px-5 py-4">
            <span className="capitalize">{t(i18nKeys.beeoinitiative.initiativesPage.page.cost)}</span>
            <Rating
              rating={initiative.cost ?? -1}
              filledColor="text-[#1F6F6C]"
              unfilledColor="text-[#D1D1D1]"
              icon={<Icons.Euro className="size-3" />}
            />
          </div>
        </div>
        <Separator />
        <div className="my-6 flex flex-row justify-center space-x-4">
          {site ? (
            <Link
              to={getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE, {
                params: { siteId: site.id, initiativeId: initiative.id },
              })}
            >
              <Button variant="outline" className="w-[95px] border-black font-semibold text-black">
                {t(i18nKeys.beeoinitiative.initiativesPage.page.detailButton)}
              </Button>
            </Link>
          ) : (
            <Link
              to={getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE, {
                params: { initiativeId: initiative.id },
              })}
            >
              <Button variant="outline" className="border-black text-black">
                {t(i18nKeys.beeoinitiative.initiativesPage.page.detailButton)}
              </Button>
            </Link>
          )}
          {site && <FormCreateActionTaken initiative={initiative} buttonWidth="w-[95px]" currentSiteId={site.id} />}
        </div>
      </div>
    </Card>
  )
}
export default InitiativeCard
