import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Progress } from '@/components/ui/progress'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

interface SitesTableProps {
  data: Site[]
}

const SitesTableBeeo = ({ data }: SitesTableProps) => {
  const { t } = useTranslation()
  const countries = {
    BE: t(i18nKeys.beeoinitiative.sites.table.BE),
    FR: t(i18nKeys.beeoinitiative.sites.table.FR),
    IT: t(i18nKeys.beeoinitiative.sites.table.IT),
  }

  const columns: ColumnDef<Site>[] = [
    {
      accessorKey: 'properties.name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.siteName)} />
      ),
      cell: ({ row }) => {
        const takenActions = row?.original?.properties?.actions_taken?.length ?? 0
        return (
          <div className="flex flex-row items-center space-x-4">
            <div
              className={`${takenActions > 0 ? 'bg-green-500' : 'bg-red-500'} size-2 rounded-full border border-transparent`}
            ></div>
            <Link
              to={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: row?.original?.id } })}
              className="max-w-[500px] truncate font-medium"
            >
              {row?.original?.properties?.name}
            </Link>
          </div>
        )
      },
    },
    {
      accessorFn: (row) => {
        const doneInits = row?.properties?.actions_taken?.filter((action) => action?.status === 'DONE')
        return doneInits
      },
      accessorKey: 'doneInits',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.takenInitiatives)} />
      ),
      cell: ({ row }) => {
        const takenActions = row.original?.properties?.actions_taken?.filter((action) => action?.status === 'DONE')
        return <span className="max-w-[500px] truncate font-medium">{takenActions?.length}</span>
      },
    },
    {
      accessorFn: (row) => {
        const plannedInits = row?.properties?.actions_taken?.filter((action) => action?.status === 'PENDING')
        return plannedInits
      },
      accessorKey: 'plannedInits',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.plannedInitiatives)} />
      ),
      cell: ({ row }) => {
        const plannedActions = row.original?.properties?.actions_taken?.filter((action) => action?.status === 'PENDING')
        return <span className="max-w-[500px] truncate font-medium">{plannedActions?.length}</span>
      },
    },
    {
      accessorKey: 'properties.initiative_score',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.score)} />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original?.properties?.initiative_score}</span>
      },
    },
    {
      accessorFn: (row) => {
        const totalBudget = row?.properties?.actions_taken
          ?.map((action) => Number(action?.planned_cost) || 0)
          .reduce((acc, curr) => acc + Number(curr), 0)
        return totalBudget
      },
      accessorKey: 'totalBudget',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.totalBudget)} />
      ),
      cell: ({ row }) => {
        const totalBudget = row.original?.properties?.actions_taken
          ?.map((action) => Number(action?.planned_cost) || 0)
          .reduce((acc, curr) => acc + Number(curr), 0)
        return <span className="max-w-[500px] truncate font-medium">{totalBudget} €</span>
      },
    },
    {
      accessorFn: (row) => {
        const country = row?.properties?.country
        return countries[country ?? '']
      },
      accessorKey: 'country',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoinitiative.sites.table.country)} />
      ),
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate font-medium">
            {countries[row?.original?.properties?.country ?? '']}
          </span>
        )
      },
    },
    {
      accessorKey: 'Progress bar',
      header: ({ column }) => <DataTableColumnHeader column={column} children="Progress bar" className="invisible" />,
      cell: ({ row }) => {
        const numberOfTakenInitiatives = row?.original?.properties?.actions_taken?.filter(
          (action) => action?.status === 'DONE'
        )
        const numberOfTakenInitiativesLength = numberOfTakenInitiatives?.length ?? 0

        const takenAndPlannedInitiatives = row?.original?.properties?.actions_taken?.length ?? 0
        const final = (numberOfTakenInitiativesLength / takenAndPlannedInitiatives) * 100
        return (
          <span>
            <Progress className="h-2" value={final} bgColor="bg-biodivLimeFiveHundred" />
          </span>
        )
      },
    },
  ]

  return <DataTable columns={columns} data={data} filterIdColumn="name" displayActions={false} />
}

export default SitesTableBeeo
