import React from 'react'
import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import { Properties } from '@/types/properties'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import BadgeWithIcon from '@/components/badge-with-icon'
import { Icons } from '@/components/icons'

type ScoreCardProps = {
  score?: number | undefined
  title: string
  description: string
  properties: Properties[]
  icon?: React.ReactNode
  scoreText: string
  link: string
}

const ScoreCard = ({ score, title, description, properties, icon, scoreText, link }: ScoreCardProps) => {
  return (
    <Card className="col-span-3 flex min-h-[400px] w-full max-w-md flex-col">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-x-2">
            {title}
            {(score && score >= 0) || score !== undefined ? (
              <div className="ml-auto">
                <BadgeWithIcon scoring={score} icon={icon} />
              </div>
            ) : null}
          </div>
        </CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>

      {score !== 0 && properties.length !== 0 ? (
        <>
          <CardContent className="grow">
            <div>
              {properties.map((property, index) => (
                <div
                  key={index}
                  className="-mx-2 flex items-start  space-x-4 rounded-md p-2 transition-all hover:bg-accent hover:text-accent-foreground"
                >
                  {property.icon}
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none">{property.value}</p>
                    <p className="text-sm text-muted-foreground">{property.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
          <CardFooter className="mt-auto justify-end">
            <Link to={link}>
              <Button variant="link" size="sm" className="flex flex-row items-center">
                {t(i18nKeys.beeoimpact.site.buttonDetails)}
                <Icons.MoveRight className="ml-2 size-4" />
              </Button>
            </Link>
          </CardFooter>
        </>
      ) : (
        <p className="px-2 text-center text-sm text-muted-foreground">{scoreText}</p>
      )}
    </Card>
  )
}

export default ScoreCard
