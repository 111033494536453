import { Audit } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import AnimalCard from '@/pages/beeoinitiative/site-detail/animal-card'
import AuditMiniCard from '@/pages/beeoinitiative/site-detail/audit-mini-card'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { dateUpdateFormat } from '@/lib/date-update-format'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card } from '@/components/ui/card'
import CardTitleXL from '@/components/ui/card-title-xl'
import { Separator } from '@/components/ui/separator'
import { Slider } from '@/components/ui/slider'
import { Icons } from '@/components/icons'

interface AuditCardProps {
  latestAudit: Audit
}

const AuditCard = ({ latestAudit }: AuditCardProps) => {
  const { t } = useTranslation()

  return (
    <Card className="mb-4 flex h-[460px] flex-col md:max-w-[696px] md:px-8 md:py-9 lg:max-w-full 2xl:px-10 2xl:py-9">
      <div className="flex items-center space-x-4 pb-6 text-xs">
        <CardTitleXL title={t(i18nKeys.beeoinitiative.site.lastAudit)} />
        <Separator orientation="vertical" className="h-4" />
        <p className="pt-1.5">{dateUpdateFormat(latestAudit.date)}</p>
        <Separator orientation="vertical" className="h-4" />
        <p className="pt-1.5">{latestAudit.surface_sq_meters} m²</p>
      </div>

      <Separator className="mb-5" />

      <div className="flex justify-center gap-5 pb-5">
        <AuditMiniCard
          name={t(i18nKeys.beeoinitiative.site.rateOfVeg)}
          number={`${latestAudit.vegetal_cover_percentage ?? 0}%`}
        />
        <AuditMiniCard
          name={t(i18nKeys.beeoinitiative.site.surfaceBioCoef)}
          number={latestAudit.surface_biotope_coefficient ?? 0}
        />
      </div>

      <div className="flex flex-col items-center justify-center space-y-4 py-9">
        <div className="flex max-w-[469px]">
          <AnimalCard
            icon={<Icons.Squirrel />}
            name={t(i18nKeys.beeoinitiative.site.animals.Mammals)}
            number={latestAudit.amount_mammals ?? 0}
          />
          <Separator orientation="vertical" className="h-4 self-center" />
          <AnimalCard
            icon={<Icons.Bug />}
            name={t(i18nKeys.beeoinitiative.site.animals.Insects)}
            number={latestAudit.amount_insects ?? 0}
          />
          <Separator orientation="vertical" className="h-4 self-center" />
          <AnimalCard
            icon={<Icons.Turtle />}
            name={t(i18nKeys.beeoinitiative.site.animals.Amphibians)}
            number={latestAudit.amount_reptiles ?? 0}
          />
        </div>
        <Separator orientation="horizontal" />
        <div className="flex max-w-[469px]">
          <AnimalCard
            icon={<Icons.Bird />}
            name={t(i18nKeys.beeoinitiative.site.animals.Birds)}
            number={latestAudit.amount_birds ?? 0}
          />
          <Separator orientation="vertical" className="h-4 self-center" />
          <AnimalCard
            icon={<Icons.Sprout />}
            name={t(i18nKeys.beeoinitiative.site.animals.Plants)}
            number={latestAudit.amount_vegetal_species ?? 0}
          />
          <Separator orientation="vertical" className="h-4 self-center" />
          <AnimalCard
            icon={<Icons.TreeDeciduous />}
            name={t(i18nKeys.beeoinitiative.site.animals.Trees)}
            number={latestAudit.amount_trees ?? 0}
          />
        </div>
      </div>

      <div className="flex items-center justify-center space-x-5 pb-6 pt-2">
        <p>{t(i18nKeys.beeoinitiative.site.indigenous)}</p>
        <Slider
          value={[latestAudit.indigenous_vegetal_percentage ?? 0]}
          max={100}
          step={1}
          className="w-[245px]"
          disabled
        />
        <p>{t(i18nKeys.beeoinitiative.site.local)}</p>
      </div>

      <Separator className="mb-5" />

      <Link
        to={getPath(Routes.BEEOAUDIT_SITE, { params: { siteId: latestAudit.site } })}
        className="flex items-center justify-center underline"
      >
        {t(i18nKeys.beeoinitiative.site.allAudits)}
      </Link>
    </Card>
  )
}

export default AuditCard
